<template>
  <table>
    <tr style="height: 50px">
      <th>序号</th>
      <th>分析工具</th>
      <th>关键缺点</th>
      <th>关键问题</th>
      <th>矛盾描述</th>
      <!-- <th>
        <v-btn v-show="!locked" dense class="ma-2" tile outlined color="success" @click="addReason">
          <v-icon left>mdi-plus</v-icon> 添加缺点
        </v-btn>
      </th> -->
    </tr>
    <tr v-for="(relation, i) in functions" :key="`relation-${i}`">
      <td>
        {{ i + 1 }}
      </td>
      <td>功能分析</td>
      <td>
        {{
          `${nodes.find((node) => node.id == relation.from).text}${relation.text}${
            nodes.find((node) => node.id == relation.to).text
          }功能${lineShapesText[relation.lineShape]}`
        }}
      </td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="问题分析总表-关键问题">
          <triz-textarea v-model="relation.issue" @input="change"></triz-textarea>
        </triz-validator>
      </td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="问题分析总表-矛盾描述">
          <triz-textarea v-model="relation.desc" @input="change"></triz-textarea>
        </triz-validator>
      </td>
    </tr>
    <tr v-for="(reason, i) in reasons" :key="`reason-${i}`">
      <td>
        {{ functions.length + i + 1 }}
      </td>
      <td>因果链分析</td>
      <td>
        {{ reason.label }}
      </td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="问题分析总表-关键问题">
          <triz-textarea v-model="reason.problem" @input="change"></triz-textarea>
        </triz-validator>
      </td>
      <td class="Triz_textarea">
        <triz-validator rules="required" id="问题分析总表-矛盾描述">
          <triz-textarea v-model="reason.desc" @input="change"></triz-textarea>
        </triz-validator>
      </td>
    </tr>
    <template v-for="(clip, i) in clips">
      <template v-for="(select, j) in clip.selected">
        <template
        v-for="(relation, k) in relationClip[i].links.filter((relation) => !relation.isHide).filter((relation) => relation.from == select.id)"
        >
          <tr :key="`clips-${i}-select-${j}-relation-${k}`">
            <td>
              {{ functions.length + reasons.length + clips.length * i + clip.selected.length * j + k + 1 }}
            </td>
            <td>功能裁剪{{i+1}}</td>
            <td>
              {{ relation.text }}
            </td>
            <td class="Triz_textarea">
              <triz-validator rules="required" id="问题分析总表-关键问题">
                <triz-textarea v-model="relation.problem" @input="change"></triz-textarea>
              </triz-validator>
            </td>
            <td class="Triz_textarea">
              <triz-validator rules="required" id="问题分析总表-矛盾描述">
                <triz-textarea v-model="relation.clipDesc" @input="change"></triz-textarea>
              </triz-validator>
            </td>
          </tr>
        </template>
      </template>
    </template>
  </table>
</template>
<script>
import { lineShapes, lineShapesText } from '../store/default'

export default {
  props: {
    issues: {
      type: Array,
      default: () => [],
    },
    reasons: {
      type: Array,
      default: () => [],
    },
    clips: {
      type: Array,
      default: () => [],
    },
    relations: {
      type: Array,
      default: () => [],
    },
    nodes: {
      type: Array,
      default: () => [],
    },
    relationClip: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lineShapes,
      lineShapesText,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    functions() {
      return this.relations
        .filter((relation) => !relation.isHide)
        .filter(
          (relation) =>
            relation.lineShape == lineShapes.bad ||
            relation.lineShape == lineShapes.unsatisfy ||
            relation.lineShape == lineShapes.over
        )
    },
  },
  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.list)
    },
  },
}
</script>
