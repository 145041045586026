import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'

import router from './router'
import store from './store'
import storage from './utils/storage'
import axios from 'axios'
import './utils/vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LockableTextArea from './components/LockableTextArea'
import LockableSelect from './components/LockableSelect'
import LockableMutiSelect from './components/LockableMutiSelect'

import new_LockableTextArea from './components/new_triz/Lockable/LockableTextArea'
import new_LockableSelect from './components/new_triz/Lockable/LockableSelect'
import new_LockableMutiSelect from './components/new_triz/Lockable/LockableMutiSelect'

import TrizValidator from './components/TrizValidator'
import VueClipboard from 'vue-clipboard2'
import 'default-passive-events'

import './styles/styles.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

//富文本
//引入quill-editor编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);
//实现quill-editor编辑器拖拽上传图片
import * as Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)
 
//实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)


Vue.component('vv-provider', ValidationProvider)
Vue.component('vv-observer', ValidationObserver)
Vue.component('triz-textarea', LockableTextArea)
Vue.component('triz-select', LockableSelect)
Vue.component('triz-muti-select', LockableMutiSelect)
Vue.component('triz-validator', TrizValidator)

Vue.component('new-triz-textarea', new_LockableTextArea)
Vue.component('new-triz-select', new_LockableSelect)
Vue.component('new-triz-muti-select', new_LockableMutiSelect)

Vue.use(VueCookies)
Vue.use(VueClipboard)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  storage,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
import Message from './components/Message'
Vue.prototype.$Message = Message
Vue.prototype.$ajax = axios
