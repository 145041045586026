<template>
	<v-container ref="content" style="padding: 0;height: 100%;margin: 0px;" @mouseover="onMouseOver" @click="onMouseClick">
		<v-form v-if="triz" >
			<v-window v-model="onboarding" :showArrows="false" @update:modelValue="modelValue">
				<!-- 1级分类循环 -->
				<v-window-item v-for="(main,n) in steps" :key="`card-${n}`" :eager="false" @group:selected="selected" style="height: 100%">
					<!-- <v-card elevation="2" height="800" class="" v-for="(main,n) in steps" :key="`card-${n}`" v-if="n==onboarding"> -->
					<!-- 2级分类循环 -->
					<v-card elevation="2" height="850" class="" v-for='sub in main.children' :key="`sub-${sub.id}`"
						v-if="activeId==sub.id || (activeId.split('-')[0]+'-'+activeId.split('-')[1])==sub.id">
						<v-card-title>
							<span class="text-h5">{{activeName?activeName:main.name}}</span>
						</v-card-title>
						<!-- 如果存在3ji分类 开始循环 如：1-2-1 -->
						<v-card-text style="overflow: auto; height: 700px;" v-if='sub.children'>
							<v-container v-for='subs in sub.children' :key="`subs-${subs.id}`" v-if=" subs.id==activeId">
								<v-row>
									<v-col cols="12" v-if="activeId=='2-1-3' || activeId=='2-1-4'|| activeId=='2-1-2'">
										<component  class="mr-6" :is="subs.component" :triz="triz" :key="new Date().getTime()"
											:parentid="`${main.id}.${subs.id}`" @change="change"
											:component="active[0].component" :toplaceholder="active[0].toplaceholder" />
									</v-col>
									<v-col cols="12" v-if="activeId!='2-1-3' && activeId!='2-1-4'&& activeId!='2-1-2'">
										<component  class="mr-6" :is="subs.component" :triz="triz" 
											:parentid="`${main.id}.${subs.id}`" @change="change"
											:component="active[0].component" :toplaceholder="active[0].toplaceholder" />
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<!-- 2级分类内容 如：1-1 -->
						<v-card-text style="overflow: auto; height: 700px;" v-if='!sub.children'>
							<v-container>
								<v-row>
									<v-col cols="12">
										<component class="mr-8" :is="sub.component" :triz="triz" 
											:parentid="`${main.id}.${sub.id}`" @change="change"
											:component="active[0].component" :toplaceholder="active[0].toplaceholder" />
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
					<!-- </v-card> -->
				</v-window-item>
			</v-window>
		</v-form>
		<v-snackbar bottom v-model="showMenu"> <strong>可添加批注 | </strong> {{ remarkTarget }} </v-snackbar>
		<v-bottom-sheet :value="showRemark" inset :hide-overlay="false" :persistent="showRemark" :retain-focus="false">
			<v-card>
				<v-text-field v-model="remark" outlined dense :label="`${remarkTarget}的批注`"></v-text-field>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="showRemark = false">关闭</v-btn>
					<v-btn color="primary" text @click="addRemark">确定</v-btn>
				</v-card-actions>
			</v-card>
		</v-bottom-sheet>
	</v-container>
</template>

<script>
	import Clip from './component/Clip'
	import Description from './component/Description.vue'
	import TechRoad from './component/TechRoad.vue'
	import Evaluation from './component/Evaluation.vue'
	import FinalResolve from './component/FinalResolve.vue'
	import Functional from './component/Functional.vue'
	import FunctionalComponent from './component/FunctionalComponent.vue'
	import FunctionalEffect from './component/FunctionalEffect.vue'
	import FunctionalModel from './component/FunctionalModel.vue'
	import FunctionalModelDiagram from './component/FunctionalModelDiagram.vue'
	import ProblemAnalysis from './component/ProblemAnalysis.vue'
	import Judgement from './component/Judgement.vue'
	import Nine from './component/Nine.vue'
	import Physical from './component/Physical.vue'
	import Reason from './component/Reason.vue'
	import Reason_tab from './component/Reason_tab.vue'
	import Resource from './component/Resource.vue'
	import Result from './component/Result.vue'
	import SCurve from './component/SCurve.vue'
	import Situation from './component/Situation.vue'
	import Solution from './component/Solution.vue'
	import Experiment from './component/Experiment.vue'
	import KeyProblem from './component/KeyProblem.vue'
	import GuideSearch from './component/GuideSearch.vue'
	import StandardResovle from './component/StandardResovle.vue'

	import BackgroudInit from './component/1-2-1-Init.vue'
	import BackgroudCurrent from './component/1-2-2-Current.vue'
	import BackgroudTarget from './component/1-2-3-Target.vue'
	import BackgroudBusiness from './component/1-2-4-Business.vue'
	import BackgroudCalculate from './component/1-2-5-Calculate.vue'
	import BackgroudLimit from './component/1-2-6-Limit.vue'
	import BackgroudAllow from './component/1-2-7-Allow.vue'

	import SigmentLink from '@/components/SigmentLink'

	import {
		TRIZ_STEPS
	} from './const'

	import editor from './component/editor/editor.vue'
	import {
		integer
	} from 'vee-validate/dist/rules'

	export default {
		name: 'App',
		components: {
			editor,
			Clip,
			Description,
			Evaluation,
			FinalResolve,
			Functional,
			FunctionalComponent,
			FunctionalEffect,
			FunctionalModel,
			FunctionalModelDiagram,
			ProblemAnalysis,
			Judgement,
			Nine,
			Physical,
			Reason,
			Reason_tab,
			Resource,
			Result,
			SCurve,
			Situation,
			Solution,
			Experiment,
			KeyProblem,
			SigmentLink,
			GuideSearch,
			BackgroudInit,
			BackgroudCurrent,
			BackgroudTarget,
			BackgroudBusiness,
			BackgroudCalculate,
			BackgroudLimit,
			BackgroudAllow,
			TechRoad,
			StandardResovle,
		},
		props: {
			isReview: {
				type: Boolean,
				default: false,
			},
			active: {
				type: Array,
				required: true
			},
			onboarding: {
				type: Number,
				required: true
			},
			open: {
				type: Array,
				required: true
			}
		},
		data() {
			return {
				// triz: null,
				steps: [...TRIZ_STEPS],
				tab: null,
				showMenu: false,
				showRemark: false,
				remark: '',
				remarkTarget: '',
				activeName: '',
				activeId: '',
				editorVal: '',
				isEditor: false,
				editorArray: ['1-1', '1-2-1', '1-2-2', '1-2-3', '1-2-4', '1-2-5', '1-2-6', '1-2-7', '5-2', '5-3', '6-1'],
				active_old:[]
			}
		},
		watch: {
			triz: {
				deep: true,
				handler() {
					// console.log('content_triz', this.triz)
					this.active = this.active
					if (this.activeId == '1-1') this.editorVal = this.triz.projectDescription
					if (this.activeId == '1-2-1') this.editorVal = this.triz.backgroud.init
					if (this.activeId == '1-2-2') this.editorVal = this.triz.backgroud.current
					if (this.activeId == '1-2-3') this.editorVal = this.triz.backgroud.target
					if (this.activeId == '1-2-4') this.editorVal = this.triz.backgroud.business
					if (this.activeId == '1-2-5') this.editorVal = this.triz.backgroud.calculate
					if (this.activeId == '1-2-6') this.editorVal = this.triz.backgroud.limit
					if (this.activeId == '1-2-7') this.editorVal = this.triz.backgroud.allow
					if (this.activeId == '5-2') this.editorVal = this.triz.finalConfirm
					if (this.activeId == '5-3') this.editorVal = this.triz.experiment
					if (this.activeId == '6-1') this.editorVal = this.triz.achievement
				}
			},
			editorVal: {
				deep: true,
				handler() {
					// console.log('content_editorVal', this.editorVal)
					if (this.activeId == '1-1') this.triz.projectDescription = this.editorVal
					if (this.activeId == '1-2-1') this.triz.backgroud.init = this.editorVal
					if (this.activeId == '1-2-2') this.triz.backgroud.current = this.editorVal
					if (this.activeId == '1-2-3') this.triz.backgroud.target = this.editorVal
					if (this.activeId == '1-2-4') this.triz.backgroud.business = this.editorVal
					if (this.activeId == '1-2-5') this.triz.backgroud.calculate = this.editorVal
					if (this.activeId == '1-2-6') this.triz.backgroud.limit = this.editorVal
					if (this.activeId == '1-2-7') this.triz.backgroud.allow = this.editorVal
					if (this.activeId == '5-2') this.triz.finalConfirm = this.editorVal
					if (this.activeId == '5-3') this.triz.experiment = this.editorVal
					if (this.activeId == '6-1') this.triz.achievement = this.editorVal
				}
			},
			active: {
				deep: false,
				handler() {
					this.activeWatch();
				}
			},
		},
		mounted() {
			// this.triz = this.$store.state.triz
			this.activeWatch();
			// console.log('content在挂载完成后调用，此时组件已经被挂载到 DOM 中，可以进行 DOM 操作')
		},
		computed: {
			triz() {
				return this.$store.state.triz
			},
			locked() {
				return this.$store.state.locked
			},
			remarks() {
				return this.$store.state.remarks
			},
		},
		methods: {
			modelValue(){
				console.log(modelValue)
			},
			selected(){
				console.log(selected)
			},
			activeWatch() {
				// console.log('content_active', this.active)
				if (this.active.length > 0) {
					this.active_old=this.active
					this.activeName = this.active[0].name
					this.activeId = this.active[0].id
					
					if (this.activeId == '1-2' || this.activeId == '2-1' || this.activeId == '2-2') {
						var isShow12 = true
						this.open.forEach((el) => {
							if ((el.id == '1-2' && this.activeId == '1-2') || (el.id == '2-1' && this.activeId ==
									'2-1') || (el.id == '2-2' && this.activeId ==
									'2-2')) {
								isShow12 = false
							}
						})
						// console.log(isShow12)
						if (isShow12) {
							this.open.push(this.active[0])
						}
						this.active[0] = this.active[0].children[0]

					}
					if (this.triz != null) {
						if (this.activeId == '1-1') this.editorVal = this.triz.projectDescription
						if (this.activeId == '1-2-1') this.editorVal = this.triz.backgroud.init
						if (this.activeId == '1-2-2') this.editorVal = this.triz.backgroud.current
						if (this.activeId == '1-2-3') this.editorVal = this.triz.backgroud.target
						if (this.activeId == '1-2-4') this.editorVal = this.triz.backgroud.business
						if (this.activeId == '1-2-5') this.editorVal = this.triz.backgroud.calculate
						if (this.activeId == '1-2-6') this.editorVal = this.triz.backgroud.limit
						if (this.activeId == '1-2-7') this.editorVal = this.triz.backgroud.allow
						if (this.activeId == '5-2') this.editorVal = this.triz.finalConfirm
						if (this.activeId == '5-3') this.editorVal = this.triz.experiment
						if (this.activeId == '6-1') this.editorVal = this.triz.achievement
					}

				}else{
					this.active=this.active_old
				}

			},
			onIntersect(entries) {
				// if (entries[0].intersectionRatio >= 0.5) {
				//   this.$store.commit('intersect', entries[0].target.id)
				// }
			},
			change(data) {
				this.$store.commit('updateTriz', data)
				this.$store.commit('do', data)
			},
			async save(projectId, version) {
				await this.$store.dispatch('saveTriz', {
					projectId,
					version
				})
				await this.$store.commit('dirty', false)
			},
			saveReview(projectId, reviewId) {
				this.$store.dispatch('insertReview', {
					projectId,
					reviewId,
					reviewStatus: '1',
					annotationContent: {
						remarks: this.remarks
					},
				})
			},
			finishReview(projectId, reviewId) {
				this.$store.dispatch('insertReview', {
					projectId,
					reviewId,
					reviewStatus: '2',
					annotationContent: {
						remarks: this.remarks
					},
				})
			},
			onMouseOver(event) {
				if (!this.isReview || !this.locked || this.showRemark) return
				const ele = (event.path || event.composedPath()).find((ele) => ele.nodeName === 'SPAN' && ele.id)
				if (ele) {
					this.remarkTarget = ele.id
					const curr = this.remarks.find((rem) => rem.target === this.remarkTarget)
					if (curr) {
						this.remark = curr.comment
					} else {
						this.remark = ''
					}

					this.showMenu = true
				} else {
					this.showMenu = false
				}
			},
			onMouseClick() {
				if (!this.isReview || !this.locked) return
				const ele = (event.path || event.composedPath()).find((ele) => ele.nodeName === 'SPAN' && ele.id)
				if (ele) {
					this.showMenu = false
					this.showRemark = true
				}
			},
			addRemark() {
				this.showRemark = false
				const curr = this.remarks.find((rem) => rem.target === this.remarkTarget)
				if (curr) {
					curr.comment = this.remark
				} else {
					this.remarks.push({
						target: this.remarkTarget,
						comment: this.remark,
					})
				}
			},
			removeRemark(i) {
				this.remarks.splice(i, 1)
			},
			addRecognizeStep(sub) {
				this.triz.selectedRecognizeSteps.push(sub)
				this.triz.selectedRecognizeSteps.sort((a, b) => a.id - b.id)
				this.$store.commit('updateTriz', this.triz)
				this.$store.commit('do', this.triz)
			},
			removeRecognizeStep(sub) {
				const step = this.triz.selectedRecognizeSteps.find((ele) => ele.id === sub.id)
				if (step) {
					const index = this.triz.selectedRecognizeSteps.findIndex((ele) => ele.id === sub.id)
					this.triz.selectedRecognizeSteps.splice(index, 1)
					this.triz.selectedRecognizeSteps.sort((a, b) => a.id - b.id)
					this.$store.commit('updateTriz', this.triz)
					this.$store.commit('do', this.triz)
				}
			},
			addResolveStep(sub) {
				this.triz.selectedResolveSteps.push(sub)
				this.triz.selectedResolveSteps.sort((a, b) => a.id - b.id)
				this.$store.commit('updateTriz', this.triz)
				this.$store.commit('do', this.triz)
			},
			removeResolveStep(sub) {
				const step = this.triz.selectedResolveSteps.find((ele) => ele.id === sub.id)
				if (step) {
					const index = this.triz.selectedResolveSteps.findIndex((ele) => ele.id === sub.id)
					this.triz.selectedResolveSteps.splice(index, 1)
					this.triz.selectedResolveSteps.sort((a, b) => a.id - b.id)
					this.$store.commit('updateTriz', this.triz)
					this.$store.commit('do', this.triz)
				}
			},
		},
	}
</script>
<style lang="scss">
	@import "../../styles/new_triz/index.scss";

	.col_analyzing_tool_btn {
		position: relative;

		.v-btn {
			position: absolute;
			right: 30px;
		}
	}

	.analyzing_tool_btn {
		margin: 0 50px;
		display: flex;
		position: relative;

		.v-btn {
			position: absolute;
			right: 0;
		}
	}
</style>