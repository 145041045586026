<template>
  <v-container class="font-size-24">
    <triz-validator rules="required" id="功能裁剪"></triz-validator>

    <template v-for="(clip, i) in triz.clips">
      <v-row :key="`clip-${i}`" align="center" justify="center"  style="height: 810px;">
				<v-col :id="`relation-graph-clip-${i}`" cols="12">
					<RelationGraph :ref="`clip-${i}`" :dragable="false" :clickable="true"
						:relation-graph-data="triz.relationGraphDataClip[i]" :allowShowMiniToolBar='false'
						@graph-data-change="refresh" :current="clip" @clip-data-change="clipChange" />
				</v-col>

			</v-row>
      <v-row :key="`clip-desc-${i}`" align="center" justify="center">
        图 功能裁剪图
      </v-row>
      <v-row :key="`clip-table-${i}`">
        <ClipTable :nodes="triz.relationGraphDataClip[i].nodes" :relations="triz.relationGraphDataClip[i].links"
          :current="clip" @change="clipChange" />
      </v-row>
      <v-row :key="`clip-table-desc-${i}`" align="center" justify="center">
        表 功能裁剪表
        <HelpIcon help="clipRule" />
      </v-row>
      <v-row :key="`clip-1-${i}`" align="center" justify="center"  style="height: 810px;">
				<v-col :id="`relation-graph-clip-after-${i}`" cols="12">
					<RelationGraph :ref="`clip-after-${i}`" :dragable="false" :clickable="false"
						:relation-graph-data="{ ...triz.relationGraphDataClip[i] }" :allowShowMiniToolBar='false'
						:current="clip" :allClips="triz.clips.filter((ele) => ele.id == clip.id)" :after="true"
						@graph-data-change="refresh" @clip-data-change="clipChange" />
				</v-col>
			</v-row>
      <v-row :key="`clip-desc-after-${i}`" align="center" justify="center"> 图 裁剪后功能图 </v-row>
      <template v-for="(resolve, j) in clip.clipResolves">
        <div :key="`resolve-${i}-${j}`">
          <v-row no-gutters>
            基于裁剪{{ i }}的解决方案{{ j + 1 }}：
          </v-row>
          <triz-validator rules="required" id="功能裁剪-解决方案">
            <Tinymce v-model="resolve.solutionDetail" @input="change" placeholder="请输入解决方案" />
          </triz-validator>
        </div>
      </template>
    </template>

  </v-container>
</template>

<script>
import RelationGraph from '@/components/new_triz/RelationGraph'
import ClipTable from '@/components/new_triz/ClipTable'
import Tinymce from '@/components/Tinymce'
import HelpIcon from '@/components/HelpIcon'

export default {
  components: {
    RelationGraph,
    ClipTable,
    Tinymce,
    HelpIcon,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },
  computed: {
    locked() {
      return this.$store.state.locked
    },
    relationGraphDataClip() {
      return this.triz.relationGraphDataClip
    },
  },
  watch: {
    relationGraphDataClip(data) {
      this.refresh(data)
    },
    
  },
  mounted() {
    this.refresh(this.triz.relationGraphDataClip)
    // this.change()
  },
  methods: {
    addClip() {
      this.triz.clips.push({
        id: new Date().getTime(),
        selected: [],
      })
      this.change()
    },
    removeClip(id) {
      const issue = this.triz.clips.find((ele) => ele.id === id)
      if (issue) {
        const index = this.triz.clips.findIndex((ele) => ele.id === id)
        this.triz.clips.splice(index, 1)
        this.change()
      }
    },
    change() {
      this.$emit('change', this.triz)
      this.refresh(this.triz.relationGraphDataClip)
    },
    refresh(data) {
      this.$nextTick(() => {
        // this.$parent.$children.find((ele) => ele.$refs.graph).$refs.graph.setJsonData(data)
        for (let i = 0; i < this.triz.clips.length; i++) {
          this.$refs[`clip-${i}`][0].setJsonData(data[i])
        }
        for (let i = 0; i < this.triz.clips.length; i++) {
          this.$refs[`clip-after-${i}`][0].setJsonData(data[i])
        }
      })
    },
    clipChange(current) {
      const target = this.triz.clips.find((clip) => clip.id === current.id)
      target.selected = current.selected
      this.change()
    },
    addClipResolve() {
      const id = `clip-${new Date().getTime()}`
      this.triz.clipResolves.push({
        id,
        solutionDetail: '',
      })
      this.triz.solutionScores.push({
        id,
      })
      this.change()
    },
    removeClipResolve(i) {
      const [{ id }] = this.triz.clipResolves.splice(i, 1)
      this.triz.solutionScores.splice(
        this.triz.solutionScores.findIndex((ele) => ele.id === id),
        1
      )
      this.change()
    },
  },
}
</script>
