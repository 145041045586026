<template>
  <v-container class="font-size-24">
    <p>纵观所有问题识别阶段，本课题共产生的问题列表如下表:</p>
    <v-row>
      <ProblemTable
      :reasons="flatTreeData(triz.reasonTreeData)" 
      :clips="triz.clips" 
      :nodes="triz.relationGraphData.nodes"
      :relations="triz.relationGraphData.links"
      :relationClip="triz.relationGraphDataClip"
      v-model="triz.problems" 
      @change="change" />
    </v-row>
    <v-row align="center" justify="center"> 表 问题列表 </v-row>
  </v-container>
</template>

<script>
import ProblemTable from '@/components/new_triz/ProblemTable'

export default {
  components: {
    ProblemTable,
  },
  props: {
    triz: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'triz',
    event: 'change',
  },
  data() {
    return {
      // triz: this.triz,
    }
  },

  mounted() {},
  methods: {
    change() {
      this.$emit('change', this.triz)
    },
    flatTreeData(tree) {
      const result = []
      if (!tree.children) return
      this.flatTree(tree.children, result)
      return result.filter((ele) => ele.selected)
    },
    flatTree(children, result) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        result.push(child)
        if (child.children) {
          this.flatTree(child.children, result)
        }
      }
    },
  },
}
</script>
