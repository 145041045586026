<template>
	<div>
	  <input
		:ref="inputref"
		type="file"
		name="img"
		accept="image/jpeg,image/jpg,image/png"
		id="quillInput"
		class="quill-img"
		multiple="multiple"
		hidden
		@change="getImgUrl($event)"
	  />
	  <quill-editor
		class="editor"
		:ref="toref"
		v-model="content"
		:options="editorOption"
		@change="onEditorChange(toref)"
		@blur="onEditorBlur($event, toref)"
		@focus="onEditorFocus($event, toref)"
	  >
	  </quill-editor>
	</div>
  </template>
  
  <script>
  import { updataFilte } from "@/api/project";
  import { mapGetters } from "vuex";
  
  // 定义最大文件大小为2M，单位转换为字节
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  
  export default {
	name: "Editor",
	props: {
	  // 编辑器的内容
	  value: {
		type: String,
	  },
	  // input的ref值
	  inputref: {
		type: String,
		default: "quillInput",
	  },
	  // quill-editor的ref值
	  toref: {
		type: String,
		default: "quillEditor",
	  },
	  // quill-editor的placeholder值
	  toplaceholder: {
		type: String,
		default: "请输入正文...",
	  },
	  // quill-editor的index值 废弃
	  quillIndex: {
		type: Number,
		default: 0,
	  },
	},
	data() {
	  return {
		content: this.value,
		editorOption: {
		  modules: {
			imageDrop: true,
			imageResize: {
			  modules: ["Resize", "DisplaySize"],
			},
			toolbar: {
			  container: [
				["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
				["blockquote", "code-block"], // 引用  代码块
				[{ header: 1 }, { header: 2 }], // 1、2 级标题
				[{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
				[{ script: "sub" }, { script: "super" }], // 上标/下标
				[{ indent: "-1" }, { indent: "+1" }], // 缩进
				// [{'direction': 'rtl'}],                         // 文本方向
				[{ size: ["small", false, "large", "huge"] }], // 字体大小
				[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
				[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
				[{ font: [] }], // 字体种类
				[{ align: [] }], // 对齐方式
				["clean"], // 清除文本格式
				["image"], // 链接、图片、视频
			  ], // 工具菜单栏配置
			  handlers: {
				"image": (value) => {
				  if (value) {
					this.$refs[this.inputref].click();
				  } else {
				  }
				},
			  },
			},
		  },
		  // placeholder: this.toplaceholder, // 提示
		  placeholder: this.toplaceholder, // 提示
		  readyOnly: false, // 是否只读
		  theme: "snow", // 主题 snow/bubble
		  syntax: true, // 语法检测
		},
	  };
	},
	computed: {
	 ...mapGetters(["HOST"]),
	},
	watch: {
	  value() {
		this.content = this.value;
	  },
	},
	mounted() {
	  this.$refs[this.toref].quill.options.placeholder = this.toplaceholder;
	},
	methods: {
	  // 检查单个文件大小是否超过限制，返回布尔值
	  checkFileSize(file) {
		return file.size <= MAX_FILE_SIZE;
	  },
	  // 构建FormData对象并添加文件
	  buildFormData(file) {
		const formData = new FormData();
		formData.append("file", file);
		return formData;
	  },
	  // 将图片插入Quill编辑器
	  insertImageToQuill(quill, url) {
		const length = quill.selection.savedRange.index;
		quill.insertEmbed(length, "image", url);
		quill.setSelection(length + 1);
	  },
	  // 聚焦事件（可删除）
	  onEditorFocus(e, ref) {
		// console.log("foucus", e, ref)
	  },
	  // 失焦事件（可删除）
	  onEditorBlur(e, ref) {
		// console.log("blur", ref)
		// console.log("inputref", this.inputref)
	  },
	  onEditorChange(toref) {
		// 触发父组件，修改父组件的值
		this.$emit("input", this.content, toref);
	  },
	  // 获取图片URL，处理文件选择事件
	  getImgUrl(e) {
		e.stopPropagation();
		const files = e.target.files;
		const validFiles = [];
		for (const file of files) {
		  if (this.checkFileSize(file)) {
			validFiles.push(file);
		  } else {
			this.$message.error("上传图片大小不能超过2M，请重新选择图片");
		  }
		}
		if (validFiles.length > 0) {
		  this.getMultipleUrl(validFiles);
		}
	  },
	  getMultipleUrl(file) {
		const uploadPromises = [];
		const quill = this.$refs[this.toref].quill;
		for (const element of file) {
		  const formData = this.buildFormData(element);
		  uploadPromises.push(
			updataFilte(formData).then((res) => {
			  if (res.code === "OK") {
				return res.data.fileUrl;
			  }
			  throw new Error("图片上传失败");
			}).catch((error) => {
			  this.$message.error(error.message);
			  return null;
			})
		  );
		}
		Promise.all(uploadPromises).then((urls) => {
		  urls.filter((url) => url).forEach((url) => {
			this.insertImageToQuill(quill, url);
		  });
		}).finally(() => {
		  this.$refs[this.inputref].value = null;
		});
	  },
	},
  };
  </script>
  
<style lang="scss" scoped>
/* 富文本编辑器 修改高度*/
.editor {
	line-height: normal !important;
	margin-bottom: 0;

}

.editor ::v-deep .ql-container {
	min-height: 200px !important;
	max-height: 600px !important;
	overflow: auto;
	font-size: 20px;
	color: black;
}

</style>
<style>
/* 修改成中文 */
.ql-snow .ql-tooltip[data-mode="link"]::before {
	content: "请输入链接地址:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
	border-right: 0px;
	content: "保存";
	padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
	content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
	content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
	content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
	content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
	content: "文本";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
	content: "标题1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
	content: "标题2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
	content: "标题3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
	content: "标题4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
	content: "标题5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
	content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
	content: "标准字体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
	content: "衬线字体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
	content: "等宽字体";
}
</style>